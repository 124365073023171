import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { GEO_GAME_ACTION, useGeoGameModuleDispatch, useGeoGameModuleState } from "..";
import useConfig from "../../config/hooks/use-config";
import getDistance from "../../geo/utils/get-distance";
import getGamePointsBerings from "../../geo/utils/get-game-points-berings";
import { getPoint } from "../../geo/utils/get-point";
import { GEO_GAME_MODE, VIEW } from "../types";
import useGeoWithLimit from "./use-geo-with-limit";

const MIN_LOCKING_RANGE = 8;

export default function useGame() {

    const dispatch = useGeoGameModuleDispatch();
    const state = useGeoGameModuleState();
    const { gamePoints, view } = state;
    const { position, accuracy, hasPosition } = useGeoWithLimit();
    const { gameMode, config } = useConfig();

    const lockingRange = useMemo(() => {
        if (!accuracy || !hasPosition) {
            return MIN_LOCKING_RANGE
        }

        return Math.max(MIN_LOCKING_RANGE, accuracy);
    }, [accuracy, hasPosition])

    useEffect(() => {
        if (gamePoints === null) {

            if (gameMode === GEO_GAME_MODE.LIPKOW) {
                dispatch({
                    type: GEO_GAME_ACTION.SET_GAME_POINTS,
                    payload: {
                        gamePoints: [
                            [52.27958, 20.80799],
                            [52.28026, 20.8084],
                            [52.2797, 20.80878],
                            [52.28027, 20.80881],
                            [52.27983, 20.80959],
                            [52.28041, 20.80949],
                            [52.281, 20.80938],
                            [52.28036, 20.80897],
                            [52.28086, 20.8085],
                            [52.28041, 20.80789]
                        ].map((p, index) => {
                            return {
                                lat: p[0],
                                lng: p[1],
                                id: `${index}`,
                                completed: false,
                                order: index
                            }
                        })
                    }
                })
            }

            if (gameMode === GEO_GAME_MODE.DEV) {
                dispatch({
                    type: GEO_GAME_ACTION.SET_GAME_POINTS,
                    payload: {
                        gamePoints: [
                            [52.203488520532595, 20.979153157769016],
                            [52.20349075451664, 20.980656195958307],
                            [52.20333768591077, 20.983688781643384],
                            [52.204963037726124, 20.984718790952975],
                            [52.204916176111006, 20.986592219002084],
                            [52.20308471772819, 20.98789461616068],
                            [52.20259956202737, 20.988775021427166],
                            [52.201587454518645, 20.9862784316925]
                        ].map((p, index) => {
                            return {
                                lat: p[0],
                                lng: p[1],
                                id: `${index}`,
                                completed: false,
                                order: index
                            }
                        })
                    }
                })
            }

            if (gameMode === GEO_GAME_MODE.AROUND && position) {
                dispatch({
                    type: GEO_GAME_ACTION.SET_GAME_POINTS,
                    payload: {
                        gamePoints: getGamePointsBerings(config.pointsCount)
                            .map(v => getPoint(v, config.distance / 1000, position))
                            .filter(Boolean as any as <T>(x: T | null) => x is T)
                            .map((v, index) => {
                                return {
                                    ...v,
                                    id: `${index}`,
                                    completed: false,
                                    order: index
                                }
                            })
                    }
                })
            }
        }
    }, [gamePoints, gameMode, position, config.distance, dispatch, config.pointsCount])

    const nextPoint = useMemo(() => {
        const unCompleted = (gamePoints || [])
            .filter(p => !p.completed)

        return unCompleted[0] ? unCompleted[0] : null;
    }, [gamePoints]);

    const distanceToNextPoint = useMemo(() => {
        if (position && nextPoint) {
            return getDistance(position, nextPoint)
        } else {
            return null;
        }
    }, [position, nextPoint]);

    const { approaching, distanceBuffer,
        oldValue,
        newValue } = useDistanceBuffer(nextPoint?.id, distanceToNextPoint);

    const isInRange = useMemo(() => {
        return distanceToNextPoint !== null && distanceToNextPoint < lockingRange / 1000;
    }, [distanceToNextPoint, lockingRange]);

    const showTask = useCallback(() => {
        dispatch({
            type: GEO_GAME_ACTION.SHOW_TASK,
            payload: {}
        })
    }, [dispatch])

    const lookingForPoint = view === VIEW.COMPASS

    useEffect(() => {
        if (isInRange && lookingForPoint) {
            const id = window.setTimeout(() => {
                showTask();
            }, 3000);

            return () => {
                window.clearTimeout(id);
            }
        }
    }, [isInRange, showTask, lookingForPoint]);

    const [gameEnded, setGameEnded] = useState(false);

    useEffect(() => {
        if (gamePoints !== null) {
            if (gamePoints.filter(p => !p.completed).length === 0) {
                setGameEnded(true);
            }
        }
    }, [gamePoints, setGameEnded])

    return {
        ...state,
        nextPoint,
        distanceToNextPoint,
        showView: useCallback((view: VIEW) => {
            dispatch({
                type: GEO_GAME_ACTION.SHOW_VIEW,
                payload: {
                    view
                }
            })
        }, [dispatch]),
        lockingRange,
        isInRange,
        gameEnded,
        approaching,
        debug: {
            distanceBuffer,
            oldValue,
            newValue
        }
    };
}

const DYNAMIC = 3;


function useDistanceBuffer(id: string | undefined | null, distance: number | null) {

    const currentPoint = useRef<string | null>();
    const [distanceBuffer, addDistance] = useReducer((state: number[], distance: number | null) => {
        if (distance === null) {
            return [];
        }

        if ((state.length < DYNAMIC + 1) || Math.abs(distance - state[state.length - 1]) > .001) {
            state = [
                ...state,
                distance
            ]
        }

        if (state.length > 5) {
            state.shift();
        }

        return state;
    }, [])


    useEffect(() => {
        if (id !== currentPoint.current) {
            addDistance(null);
            currentPoint.current = id;
        } else {
            if (distance) {
                addDistance(distance);
            }
        }

    }, [distance, id])

    const approaching = useMemo(() => {
        if (distanceBuffer.length < DYNAMIC + 1) {
            return { value: null, o: 0, n: 0 }
        }

        const oldValue = distanceBuffer.slice(0, DYNAMIC).reduce((prev, next) => {
            return prev + next
        }, 0) / DYNAMIC;

        const newValue = distanceBuffer.slice(1, DYNAMIC + 1).reduce((prev, next) => {
            return prev + next
        }, 0) / DYNAMIC;

        return { value: oldValue > newValue, oldValue, newValue }
    }, [distanceBuffer])




    return {
        approaching: approaching.value,
        oldValue: approaching.oldValue,
        newValue: approaching.newValue,
        distanceBuffer
    }
}
