import { useEffect, useReducer, useRef } from "react";
import styled from "styled-components"
import useGame from "../../../../modules/game/hooks/use-game";
import useGeo from "../../../../modules/geo/hooks/use-geo";

declare const google: any;

type Props = {}

const Container = styled.div`
    width: 100%;
    height: 80%;


    display: flex;
    align-items: stretch;

    pointer-events: all;

    #map {
        flex: 1 1 100%;
    }
`;

type LatLng = { lat: number, lng: number }

const MapComponent: React.FC<Props> = () => {

    const mapContainer = useRef<HTMLDivElement>(null);
    const { position } = useGeo();
    const {gamePoints: points} = useGame();
    const posMarker = useRef<any>();
    const mapRef = useRef<any>();


    const [startPosition, setStartPosition] = useReducer((prevState: LatLng | null, action: LatLng | null) => {
        return prevState || action;
    }, null);

    useEffect(() => {
        if (position) {
            setStartPosition(position);
        }
    }, [position, setStartPosition])

    useEffect(() => {
        mapRef.current = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 52.2110, lng: 20.9752 },
            zoom: 10,
            mapTypeId: 'satellite'
        });
    }, [mapRef])

    useEffect(() => {
        if (mapRef.current && startPosition) {
            posMarker.current = new google.maps.Marker({
                icon: '//maps.google.com/mapfiles/kml/shapes/man.png',
                position: startPosition,
                map: mapRef.current
            });
        }
    }, [mapRef, startPosition])

    useEffect(() => {
        if (mapRef.current) {

            points?.forEach(({ lat, lng }) => {
                new google.maps.Marker({
                    position: {
                        lat,
                        lng
                    },
                    map: mapRef.current
                });
            })

        }

    }, [mapRef, points]);

    useEffect(() => {
        if (posMarker.current && position) {
            posMarker.current.setPosition(position)
        }
    }, [posMarker, position]);



    return <Container>
        <div id="map" ref={mapContainer}></div>
    </Container>
}

export default MapComponent;
