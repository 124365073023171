import React, { useEffect, useMemo, useReducer, useState } from "react"
import useConfig from "../../modules/config/hooks/use-config";
import useGame from "../../modules/game/hooks/use-game";
import useGeoWithLimit from "../../modules/game/hooks/use-geo-with-limit";
import { GamePoint, VIEW } from "../../modules/game/types";
import { GEO_MODULE_ERROR } from "../../modules/geo/types";
import BackgroundComponent from "./components/background"
import Compass from "./components/compass";
import DebugBox from "./components/debug";
import CameraPermissionDeniedPopup from "./components/errors/camera-permition";
import PermissionDeniedPopup from "./components/errors/permition-denied";
import GeoTask from "./components/geo-task";
import Intro from "./components/intro";
import NoPosition from "./components/no-position";
import './style.scss';

type Props = {
    onGameEnded: () => void
}

const GameScreen: React.FC<Props> = ({onGameEnded}) => {

    const { error, hasPosition, accuracy } = useGeoWithLimit();
    const { view, nextPoint, showView, distanceToNextPoint, gamePoints, gameEnded, approaching } = useGame();
    const { config } = useConfig();

    useEffect(() => {
        if (error === GEO_MODULE_ERROR.PERMISSION_DENIED) {
            showView(VIEW.GEO_INFO);
        }
    }, [error, showView])

    useEffect(() => {
        if (gameEnded) {
            onGameEnded();
        }
    }, [gameEnded, onGameEnded])

    const showPermitionDeniedPopup = (view === VIEW.GEO_INFO);
    const showCompass = (view === VIEW.COMPASS) && (distanceToNextPoint !== null);
    const showIntro = (view === VIEW.INTRO);
    const showTask = (view === VIEW.TAKS) && (nextPoint !== null);
    const [showDebug, toggleDebug] = useReducer((state) => !state, false)

    const showNoPosition = !showPermitionDeniedPopup && !hasPosition;
    const [showNoCameraPermition, setNoCameraPermition] = useState(false);

    const remainigPointsCount = useMemo(() => {
        if (gamePoints === null) {
            return 0;
        }

        return gamePoints.filter(p => !p.completed).length;
    }, [gamePoints])

    return <div className="geo-game-screen game-screen">
        <div className="geo-game-background">
            <BackgroundComponent onPermitionError={() => setNoCameraPermition(true)} />
        </div>

        <div className="front-layer">
            {showPermitionDeniedPopup && <PermissionDeniedPopup />}
            {showNoCameraPermition && <CameraPermissionDeniedPopup onNext={() => setNoCameraPermition(false)} />}

            {showCompass && <Compass distance={distanceToNextPoint as number} approaching={approaching} />}
            {showIntro && <Intro />}
            {showNoPosition && <NoPosition accuracy={accuracy} limit={config.maxError} />}
            {showTask && <GeoTask point={nextPoint as GamePoint} pointCount={gamePoints?.length || 0} remainigPointsCount={remainigPointsCount} />}
            {showDebug && <DebugBox />}
            {true && <button style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                pointerEvents: 'all',
                fontSize: '24px',
                zIndex: 1000
            }} onClick={toggleDebug}>DEBUG!</button>}
        </div>
    </div>
}

export default GameScreen;
