import styled from "styled-components"
import useGame from "../../../../modules/game/hooks/use-game";
import MapComponent from "../map";

type Props = {}

const Container = styled.div`
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 50%;

    z-index: 1000;

    background-color: pink;
`;

const DebugBox: React.FC<Props> = () => {

    const {distanceToNextPoint, lockingRange, isInRange, debug} = useGame();

    return <Container>
        <MapComponent />
    </Container>
}

export default DebugBox;
