import makeStore from "@mborecki/react-create-store";
import { GeoGameConfig, GEO_GAME_MODE } from "../game/types";
import reducer from './reducer';

export type GeoGameConfigState = {
    gameMode: GEO_GAME_MODE,
    config: GeoGameConfig,
    settings: {
        tryCamera: true
    }
}

export enum GEO_GAME_CONFIG_ACTION {
    SET_CONFIG = 'set-config',
    SET_SETTINGS = 'set-settings'
}

export type GeoGameConfigAction = {
    type: GEO_GAME_CONFIG_ACTION,
    payload: any,
}

export const initGeoGameConfigModuleState: GeoGameConfigState = {
    gameMode: GEO_GAME_MODE.AROUND,
    config: {
        pointsCount: 5,
        distance: 30,
        maxError: 20,
    },
    settings: {
        tryCamera: true
    }
}

const [
    GeoGameConfigModuleProvider,
    useGeoGameConfigModuleDispatch,
    useGeoGameConfigModuleState
] = makeStore(reducer, initGeoGameConfigModuleState)

export {
    GeoGameConfigModuleProvider,
    useGeoGameConfigModuleDispatch,
    useGeoGameConfigModuleState
}
